import { useEffect } from 'react'

import { useCustomerEmailsQuery } from '@twisto/api-graphql'
import { useAppConfig } from '@twisto/base/app-config'

import { useCustomerConfig } from 'customer/components/customer-config'

export const LiveChat = () => {
  const { data, loading } = useCustomerEmailsQuery()
  const { DAKTELA_GUI_SERVER, support } = useAppConfig()
  const { featureFlags } = useCustomerConfig()
  const { daktelaGuiToken } = support

  useEffect(() => {
    if (loading) {
      return
    }

    if (!featureFlags.omnichannel) {
      return
    }

    const daktelaGuiConfig = {
      server: DAKTELA_GUI_SERVER,
      accessToken: daktelaGuiToken,
      settings: {
        inputs: {
          email: { defaultValue: data?.customerEmails.primary.email },
        },
      },
    }

    const daktelaScriptEl = document.createElement('script')
    daktelaScriptEl.src = `${daktelaGuiConfig.server}external/web/web.js`
    daktelaScriptEl.type = 'text/javascript'
    daktelaScriptEl.async = true

    daktelaScriptEl.onload = function () {
      // eslint-disable-next-line new-cap
      const daktelaWeb = new window.daktelaGui()

      daktelaWeb.init(daktelaGuiConfig)
    }

    document.head.appendChild(daktelaScriptEl)
  }, [data, loading, DAKTELA_GUI_SERVER, daktelaGuiToken, featureFlags])

  return null
}
